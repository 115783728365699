import { Fragment, useState, FC, useEffect } from "react";
import { Popover, Transition } from "@headlessui/react";
import { CalendarIcon } from "@heroicons/react/24/outline";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import ClearDataButton from "components/HeroSearchForm/ClearDataButton";
import DatePickerCustomHeaderTwoMonth from "components/DatePickerCustomHeaderTwoMonth";
import DatePickerCustomDay from "components/DatePickerCustomDay";
import fr from "date-fns/locale/fr";
registerLocale("fr", fr);
setDefaultLocale("fr");
export interface StayDatesRangeInputProps {
  className?: string;
  onTotalDayChange: (day: Date) => void;
  availableDays: any;
  fullyDays: any;
  unavailableDays?: any;
}

const StayDatesRangeInput: FC<StayDatesRangeInputProps> = ({
  className = "flex-1",
  onTotalDayChange,
  availableDays,
  fullyDays,
  unavailableDays,
}) => {
  const [startDate, setStartDate] = useState<Date | null>();
  const onChangeDate = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    if (selectedDate !== null) {
      onTotalDayChange(selectedDate);
    }
  };

  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  function convertDaysToNumbers(daysArray: any) {
    const daysOfWeek = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"];
    return daysArray.map((dayName: any) => {
      const index = daysOfWeek.indexOf(dayName);
      return index !== -1 ? index : null;
    });
  }

  const availableDayNumbers = convertDaysToNumbers(availableDays);
  const getDaysBetween = (start: any, end: any) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const days = [];
    for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
      days.push(date.toISOString().split("T")[0]);
    }
    days.push(endDate.toISOString().split("T")[0]);
    return days;
  };
  const getFullyDaysFromUnavailableDays = () => {
    const result: any[] = [];

    unavailableDays.forEach(({ startDay, endDay }: any) => {
      const daysBetween = getDaysBetween(startDay, endDay);
      result.push(...daysBetween);
    });

    const uniqueResult = Array.from(new Set(result));

    return uniqueResult;
  };

  const fullyDaysFromUnavailableDays = getFullyDaysFromUnavailableDays();
  const excludedays = (date: Date) => {
    const dayOfWeek = date.getDay();

    const isFullyDay = (fullyDay: string) => {
      const fullyDate = new Date(fullyDay);
      return (
        fullyDate.getFullYear() === date.getFullYear() &&
        fullyDate.getMonth() === date.getMonth() &&
        fullyDate.getDate() === date.getDate()
      );
    };

    const isUnavailableDay = (unavailableDay: string) => {
      const unavailableDate = new Date(unavailableDay);
      return (
        unavailableDate.getFullYear() === date.getFullYear() &&
        unavailableDate.getMonth() === date.getMonth() &&
        unavailableDate.getDate() === date.getDate()
      );
    };

    const isExcluded =
      fullyDays.includes(formatDate(date)) ||
      fullyDaysFromUnavailableDays.some(isFullyDay) ||
      unavailableDays.some(isUnavailableDay);

    return !isExcluded && availableDayNumbers.includes(dayOfWeek);
  };
  const formatDate = (date: Date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const renderInput = () => {
    return (
      <>
        <div className="text-black-300 dark:text-neutral-400">
          <CalendarIcon className="inline-flex w-6 h-6 rounded-full opacity-75 animate-bounce lg:w-7 lg:h-7" />
        </div>
        <div className="flex-grow text-left ">
          <span className="block font-semibold xl:text-lg">
            {startDate?.toLocaleDateString("en-US", {
              month: "short",
              day: "2-digit",
            }) || "Ajouter le jour souhaité"}
          </span>
          <span className="block mt-1 text-sm font-light leading-none text-neutral-400"></span>
        </div>
      </>
    );
  };

  return (
    <Popover className={`StayDatesRangeInput z-10 relative flex ${className}`}>
      {({ open, close }) => (
        <>
          <Popover.Button
            className={`flex-1 flex relative p-3 items-center space-x-3 focus:outline-none ${
              open ? "shadow-lg" : ""
            }`}
          >
            {renderInput()}
            {startDate && open && <ClearDataButton onClick={() => onChangeDate([null, null])} />}
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute right-0 left-auto z-10 w-screen max-w-sm px-4 mt-3 xl:-right-10 top-full sm:px-0 lg:max-w-3xl">
              <div className="p-8 overflow-hidden bg-white shadow-lg rounded-3xl ring-1 ring-black ring-opacity-5 dark:bg-neutral-800 ">
                <DatePicker
                  locale={"fr"}
                  onChange={(date) => {
                    if (date !== null) {
                      setSelectedDate(date);
                      setStartDate(date);
                      onTotalDayChange(date);
                      close();
                    }
                  }}
                  selected={selectedDate}
                  monthsShown={2}
                  minDate={new Date()}
                  showPopperArrow={false}
                  filterDate={excludedays}
                  inline
                  renderCustomHeader={(p) => <DatePickerCustomHeaderTwoMonth {...p} />}
                  renderDayContents={(day, date) => <DatePickerCustomDay dayOfMonth={day} date={date} />}
                />
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default StayDatesRangeInput;
