import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Balance, Transaction, TransactionList } from "data/types";

import { RootState } from "redux/store";
import { stripeApi } from "./stripeApi";

interface StripeState {
  loading: "idle" | "pending" | "succeeded" | "failed";
  balanceData?: Balance;
  transactionsList?: Transaction[];
  totalPages?: number;
  totalItems?: number;
}

const initialState: StripeState = {
  loading: "idle",
  balanceData: undefined,
  transactionsList: undefined,
  totalPages: 0,
  totalItems: 0,
};

export const getBalance = createAsyncThunk("getBalance", async () => {
  return await stripeApi.getBalance();
});

export const getAllTransactions = createAsyncThunk("getAllTransactions", async () => {
  const data: TransactionList = await stripeApi.getAllTransactions();
  return data;
});

export const stripeSlice = createSlice({
  name: "Stripe",
  initialState,
  reducers: {
    initStates(state) {
      return {
        ...state,
        loading: "idle",
        balanceData: undefined,
        transactionsList: [],
        totalPages: 0,
        totalItems: 0,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBalance.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getBalance.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.balanceData = action.payload;
      })
      .addCase(getBalance.rejected, (state) => {
        state.loading = "failed";
      });

    builder
      .addCase(getAllTransactions.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getAllTransactions.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.transactionsList = action.payload.data;
        state.totalPages = Math.ceil(action.payload.data.length / 10);
        state.totalItems = action.payload.data.length;
      })
      .addCase(getAllTransactions.rejected, (state) => {
        state.loading = "failed";
      });
  },
});

export const { initStates } = stripeSlice.actions;

export const selectCount = (state: RootState) => state.stripe;
export default stripeSlice.reducer;
