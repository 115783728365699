import React, { FC, useContext, useEffect, useState } from "react";

import { Link, useLocation } from "react-router-dom";

import { ChevronDownIcon, HeartIcon, HomeIcon } from "@heroicons/react/24/outline";
import { CalendarDaysIcon, LockClosedIcon, UserCircleIcon } from "@heroicons/react/24/solid";
import {
  Accordion,
  Button,
  Card,
  List,
  ListItem,
  ListItemPrefix,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Typography,
} from "@material-tailwind/react";
import { AuthContext } from "context/appContext";
import { ProfilClient } from "data/types";

export interface CommonLayoutProps {
  children?: React.ReactNode;
  clientName?: string;
  id?: string;
}

const CommonLayout: FC<CommonLayoutProps> = ({ children }) => {
  const authContext = useContext(AuthContext);
  const profilId = authContext?.user?.profil?.id;
  let profile = authContext.user?.profil as ProfilClient;
  const location = useLocation();
  const isActivePath = (path: string) => location.pathname === path;

  const [open, setOpen] = React.useState(0);
  const handleOpen = (value: React.SetStateAction<number>) => {
    setOpen(open === value ? 0 : value);
  };
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1023);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="flex flex-col lg:flex-row">
      {isMobile ? (
        <Menu
          animate={{
            mount: { y: 0 },
            unmount: { y: 25 },
          }}
        >
          <MenuHandler>
            <Button className="dark:text-dark">
              {" "}
              <div>
                <i className="mt-1 text-xl las la-bars"></i>
              </div>{" "}
              &nbsp; Dashboard
            </Button>
          </MenuHandler>
          <MenuList>
            <MenuItem className="flex items-center">
              <Link to="/client" className="flex items-center">
                <UserCircleIcon className="h-5 mr-2 w-9" />
                Profil
              </Link>
            </MenuItem>
            <MenuItem className="flex items-center">
              <Link to="/client-password" className="flex items-center">
                <LockClosedIcon className="h-5 mr-2 w-9" />
                Mot de passe
              </Link>
            </MenuItem>

            {profile?.validation === "en cours" && (
              <MenuItem className="flex items-center">
                <Link to="/client/demande" className="flex items-center">
                  <HomeIcon className="h-5 mr-2 w-9" />
                  Mes espaces
                </Link>
              </MenuItem>
            )}

            {profile?.validation === "approuvé" && (
              <MenuItem className="flex items-center">
                <Link to="/client/locations" className="flex items-center">
                  <HomeIcon className="h-5 mr-2 w-9" />
                  Mes espaces
                </Link>
              </MenuItem>
            )}

            {profile?.validation != "approuvé" && (
              <MenuItem className="flex items-center">
                <Link to="/client-reservations" className="flex items-center">
                  <CalendarDaysIcon className="h-5 mr-2 w-9" />
                  Réservations
                </Link>
              </MenuItem>
            )}
            {profile?.validation === "approuvé" && (
              <MenuItem className="flex items-center">
                <Link to="/client/my-reservations" className="flex items-center">
                  <CalendarDaysIcon className="h-5 mr-2 w-9" />
                  Réservations
                </Link>
              </MenuItem>
            )}
            {profile?.validation === "approuvé" && (
              <MenuItem className="flex items-center">
                <Link to="/client/list-pending-reservations" className="flex items-center">
                  <CalendarDaysIcon className="h-5 mr-2 w-9" />
                  Demande de Réservations
                </Link>
              </MenuItem>
            )}

            <MenuItem className="flex items-center">
              <Link to="/client-savelists" className="flex items-center">
                <HeartIcon className="h-5 mr-2 w-9" />
                Espace favoris
              </Link>
            </MenuItem>

            <MenuItem className="flex items-center">
              <Link to="/client-serviceslist" className="flex items-center">
                <HeartIcon className="h-5 mr-2 w-9" />
                Prestations favoris
              </Link>
            </MenuItem>
          </MenuList>
        </Menu>
      ) : (
        <div className="p-6 border-r lg:w-1/4 border-neutral-200 dark:border-neutral-700">
          <Card className="h-full lg:h-[calc(100vh-2rem)] w-full max-w-[20rem] lg:max-w-none p-4 shadow-xl shadow-blue-gray-900/5 dark:bg-gray-800 dark:text-white">
            <div className="p-4 mb-2">
              <Typography variant="h5" color="blue-gray">
                Bienvenue
              </Typography>
            </div>
            <List>
              <Accordion
                open={open === 1}
                icon={
                  <ChevronDownIcon
                    strokeWidth={2.5}
                    className={`mx-auto h-4 w-4 transition-transform ${open === 1 ? "rotate-180" : ""}`}
                  />
                }
              >
                <ListItem className={isActivePath("/client") ? "font-bold text-eventToBee" : ""}>
                  <ListItemPrefix>
                    <UserCircleIcon className="h-5 w-9" />
                  </ListItemPrefix>
                  <Link to="/client">Profil</Link>
                </ListItem>

                <ListItem className={isActivePath("/client-password") ? "font-bold text-eventToBee" : ""}>
                  <ListItemPrefix>
                    <LockClosedIcon className="h-5 w-9" />
                  </ListItemPrefix>
                  <Link to="/client-password">Mot de passe</Link>
                </ListItem>
                {profile?.validation === "en cours" && (
                  <ListItem className={isActivePath("/client/demande") ? "font-bold text-eventToBee" : ""}>
                    <ListItemPrefix>
                      <HomeIcon className="h-5 w-9" />
                    </ListItemPrefix>
                    <Link to="/client/demande">Mes espaces</Link>
                  </ListItem>
                )}

                {profile?.validation === "approuvé" && (
                  <ListItem className={isActivePath("/client/locations") ? "font-bold text-eventToBee" : ""}>
                    <ListItemPrefix>
                      <HomeIcon className="h-5 w-9" />
                    </ListItemPrefix>
                    <Link to="/client/locations">Mes espaces</Link>
                  </ListItem>
                )}

                {profile?.validation != "approuvé" && (
                  <ListItem className={isActivePath("/client-reservations") ? "font-bold text-eventToBee" : ""}>
                    <ListItemPrefix>
                      <CalendarDaysIcon className="h-5 w-9" />
                    </ListItemPrefix>
                    <Link to="/client-reservations">Réservations</Link>
                  </ListItem>
                )}
                {profile?.validation === "approuvé" && (
                  <ListItem className={isActivePath("/client/my-reservations") ? "font-bold text-eventToBee" : ""}>
                    <ListItemPrefix>
                      <CalendarDaysIcon className="h-5 w-9" />
                    </ListItemPrefix>
                    <Link to="/client/my-reservations">Réservations</Link>
                  </ListItem>
                )}
                {profile?.validation === "approuvé" && (
                  <ListItem
                    className={
                      isActivePath("/client/list-pending-reservations") ? "font-bold text-eventToBee" : ""
                    }
                  >
                    <ListItemPrefix>
                      <CalendarDaysIcon className="h-5 w-9" />
                    </ListItemPrefix>
                    <Link to="/client/list-pending-reservations">Demande de Réservations</Link>
                  </ListItem>
                )}
                <ListItem className={isActivePath("/client-savelists") ? "font-bold text-eventToBee" : ""}>
                  <ListItemPrefix>
                    <HeartIcon className="h-5 w-9" />
                  </ListItemPrefix>
                  <Link to="/client-savelists">Espace favoris</Link>
                </ListItem>
                <ListItem className={isActivePath("/client-serviceslist") ? "font-bold text-eventToBee" : ""}>
                  <ListItemPrefix>
                    <HeartIcon className="h-5 w-9" />
                  </ListItemPrefix>
                  <Link to="/client-serviceslist">Prestations favoris</Link>
                </ListItem>
              </Accordion>
            </List>
          </Card>
        </div>
      )}
      <div className="container pb-24 pt-14 sm:pt-20 lg:pb-32">{children}</div>
    </div>
  );
};

export default CommonLayout;
