import React, { FC } from "react";
import { Link, useLocation } from "react-router-dom";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Card,
  List,
  ListItem,
  ListItemPrefix,
  Typography,
} from "@material-tailwind/react";
import { HiOutlineSquare3Stack3D } from "react-icons/hi2";
export interface DashboardMenuProps {
  children?: React.ReactNode;
}

const DashboardMenu: FC<DashboardMenuProps> = ({ children }) => {
  const [open, setOpen] = React.useState(0);
  const [opene, setOpened] = React.useState(0);
  const [showClientsAndAgents, setShowClientsAndAgents] = React.useState(true);

  const handleOpen = (value: React.SetStateAction<number>) => {
    setOpen(open === value ? 0 : value);
  };

  const [subOpen, setSubOpen] = React.useState(0);

  const handleSubOpen = (value: number) => {
    setSubOpen(subOpen === value ? 0 : value);
  };

  const location = useLocation();

  React.useEffect(() => {
    const { pathname } = location;

    if (pathname.includes("/dashboard/stats")) {
      setOpen(1);
    } else if (pathname.includes("/dashboard/locations/")) {
      setOpen(2);
      setSubOpen(4);
    } else if (pathname.includes("/dashboard/categories")) {
      setOpen(2);
      setSubOpen(5);
    } else if (pathname.includes("/dashboard/equipments")) {
      setOpen(2);
      setSubOpen(6);
    } else if (pathname.includes("/dashboard/events")) {
      setOpen(2);
      setSubOpen(7);
    } else if (pathname.includes("/dashboard/atmospheres")) {
      setOpen(2);
      setSubOpen(8);
    } else if (pathname.includes("/dashboard/playgrounds")) {
      setOpen(2);
      setSubOpen(9);
    } else if (pathname.includes("/dashboard/rules")) {
      setOpen(2);
      setSubOpen(10);
    } else if (pathname.includes("/dashboard/creator-list")) {
      setOpen(3);
      setSubOpen(11);
    } else if (pathname.includes("/dashboard/owners")) {
      setOpen(3);
      setSubOpen(12);
    } else if (pathname.includes("/dashboard/agents")) {
      setOpen(3);
      setSubOpen(13);
    } else if (pathname.includes("/dashboard/clients")) {
      setOpen(3);
      setSubOpen(14);
    } else if (pathname.includes("/dashboard/prestations")) {
      setOpen(4);
      setSubOpen(15);
    } else if (pathname.includes("/dashboard/fields")) {
      setOpen(4);
      setSubOpen(16);
    } else if (pathname.includes("/dashboard/slider")) {
      setOpen(5);
      setSubOpen(17);
    } else if (pathname.includes("/dashboard/videos")) {
      setOpen(5);
      setSubOpen(18);
    } else if (pathname.includes("/dashboard/reservation-admin")) {
      setOpen(6);
      setSubOpen(19);
    } else if (pathname.includes("/dashboard/reservations/tentative-admin")) {
      setOpen(6);
      setSubOpen(22);
    } else if (pathname.includes("/dashboard/reservations/pending-admin")) {
      setOpen(6);
      setSubOpen(23);
    } else if (pathname.includes("/dashboard/canceled-admin")) {
      setOpen(6);
      setSubOpen(21);
    } else if (pathname.includes("/dashboard/pending-admin")) {
      setOpen(6);
      setSubOpen(20);
    } else if (pathname.includes("/dashboard/home-videos")) {
      setOpen(5);
      setSubOpen(19);
    } else if (pathname.includes("/dashboard/emails")) {
      setOpen(5);
      setSubOpen(24);
    } else if (pathname.includes("/dashboard/stripe-connect")) {
      setOpen(5);
      setSubOpen(25);
    } else {
      setOpen(0);
      setSubOpen(0);
    }
  }, [location]);

  return (
    <div className="flex flex-col lg:flex-row bg-neutral-50 dark:bg-neutral-900">
      <div className="p-6 border-r lg:w-1/4 border-neutral-200 dark:border-neutral-700">
        <Card className="h-full lg:h-[calc(100vh-2rem)] w-full max-w-[20rem] lg:max-w-none p-4 shadow-xl shadow-blue-gray-900/5 dark:bg-gray-800">
          <div className="p-4 mb-2">
            <Typography variant="h5" color="blue-gray" className="dark:text-white">
              Bienvenue
            </Typography>
          </div>
          <List>
            <Accordion
              open={open === 1}
              icon={
                <ChevronDownIcon
                  strokeWidth={2.5}
                  className={`mx-auto h-4 w-4 transition-transform dark:text-white ${
                    open === 1 ? "rotate-180" : ""
                  }`}
                />
              }
            >
              <ListItem className="p-0" selected={open === 1}>
                <AccordionHeader onClick={() => handleOpen(1)} className="p-3 border-b-0">
                  <ListItemPrefix>
                    <i className="text-2xl las la-chart-area dark:text-white"></i>
                  </ListItemPrefix>
                  <Typography color="blue-gray" className="mr-auto font-normal dark:text-white">
                    Statistiques
                  </Typography>
                </AccordionHeader>
              </ListItem>
              <AccordionBody className="py-1">
                <>
                  <AccordionBody className="py-1">
                    <List className="p-0">
                      <Link to="/dashboard/stats/clients" onClick={() => handleSubOpen(11)}>
                        <ListItem>
                          <ListItemPrefix>
                            <i className="las la-user text-1xl dark:text-white"></i>
                          </ListItemPrefix>
                          <span className={subOpen === 11 ? "font-bold p-1 dark:text-white" : "dark:text-white"}>
                            Clients
                          </span>
                        </ListItem>
                      </Link>
                    </List>
                    <List className="p-0">
                      <Link
                        to="/dashboard/stats/agents"
                        className={subOpen === 12 ? "font-bold p-1" : "dark:text-white"}
                        onClick={() => handleSubOpen(12)}
                      >
                        <ListItem>
                          <ListItemPrefix>
                            <i className="las la-hands-helping text-1xl dark:text-white"></i>
                          </ListItemPrefix>
                          <span className={subOpen === 12 ? "font-bold p-1 dark:text-white" : "dark:text-white"}>
                            Prestataires
                          </span>
                        </ListItem>
                      </Link>
                    </List>
                    <List className="p-0">
                      <Link to="/dashboard/stats/owner" onClick={() => handleSubOpen(13)}>
                        <ListItem>
                          <ListItemPrefix>
                            <i className="las la-home text-1xl dark:text-white"></i>
                          </ListItemPrefix>
                          <span className={subOpen === 13 ? "font-bold p-1 dark:text-white" : "dark:text-white"}>
                            Propriétaires
                          </span>
                        </ListItem>
                      </Link>
                    </List>
                  </AccordionBody>
                </>
              </AccordionBody>
            </Accordion>
            <Accordion
              open={open === 2}
              icon={
                <ChevronDownIcon
                  strokeWidth={2.5}
                  className={`mx-auto h-4 w-4 transition-transform dark:text-white${
                    open === 2 ? "rotate-180" : ""
                  }`}
                />
              }
            >
              <ListItem className="p-0" selected={open === 2}>
                <AccordionHeader onClick={() => handleOpen(2)} className="p-3 border-b-0">
                  <ListItemPrefix>
                    <i className="las la-home dark:text-white"></i>
                  </ListItemPrefix>
                  <Typography color="blue-gray" className="mr-auto font-normal dark:text-white">
                    Espaces
                  </Typography>
                </AccordionHeader>
              </ListItem>

              <AccordionBody className="py-1">
                <List className="p-0">
                  <Link to="/dashboard/locations/unApprouved" onClick={() => handleSubOpen(4)}>
                    <ListItem>
                      <ListItemPrefix
                        onClick={(event: any) => {
                          event.stopPropagation();
                        }}
                      >
                        <i className="las la-place-of-worship text-1xl dark:text-white"></i>
                      </ListItemPrefix>
                      <span className={subOpen === 4 ? "font-bold p-1 dark:text-white" : "dark:text-white"}>
                        {" "}
                        Liste des espaces
                      </span>
                    </ListItem>
                  </Link>
                </List>
                <List className="p-0">
                  <Link to="/dashboard/categories" onClick={() => handleSubOpen(5)}>
                    <ListItem>
                      <ListItemPrefix>
                        <HiOutlineSquare3Stack3D strokeWidth={3} className="w-5 h-3 dark:text-white" />
                      </ListItemPrefix>

                      <span className={subOpen === 5 ? "font-bold p-1 dark:text-white" : "dark:text-white"}>
                        Categorie
                      </span>
                    </ListItem>
                  </Link>
                </List>
                <List className="p-0">
                  <Link to="/dashboard/equipments" onClick={() => handleSubOpen(6)}>
                    <ListItem>
                      <ListItemPrefix>
                        <i className="las la-tools text-1xl dark:text-white"></i>
                      </ListItemPrefix>
                      <span className={subOpen === 6 ? "font-bold p-1 dark:text-white" : "dark:text-white"}>
                        Equipements
                      </span>
                    </ListItem>
                  </Link>
                </List>
                <List className="p-0">
                  <Link to="/dashboard/events" onClick={() => handleSubOpen(7)}>
                    <ListItem>
                      <ListItemPrefix>
                        <i className="las la-glass-cheers text-1xl dark:text-white"></i>
                      </ListItemPrefix>

                      <span className={subOpen === 7 ? "font-bold p-1 dark:text-white" : "dark:text-white"}>
                        Évènements
                      </span>
                    </ListItem>
                  </Link>
                </List>
                <List className="p-0">
                  <Link to="/dashboard/atmospheres" onClick={() => handleSubOpen(8)}>
                    <ListItem>
                      <ListItemPrefix>
                        <i className="las la-laugh-beam text-1xl dark:text-white"></i>
                      </ListItemPrefix>

                      <span className={subOpen === 8 ? "font-bold p-1 dark:text-white" : "dark:text-white"}>
                        Ambiances
                      </span>
                    </ListItem>
                  </Link>
                </List>
                <List className="p-0">
                  <Link to="/dashboard/playgrounds" onClick={() => handleSubOpen(9)}>
                    <ListItem>
                      <ListItemPrefix>
                        <i className="las la-hand-scissors text-1xl dark:text-white"></i>
                      </ListItemPrefix>
                      <span className={subOpen === 9 ? "font-bold p-1 dark:text-white" : "dark:text-white"}>
                        Aire de jeux
                      </span>
                    </ListItem>
                  </Link>
                </List>
                <List className="p-0">
                  <Link to="/dashboard/rules" onClick={() => handleSubOpen(10)}>
                    <ListItem>
                      <ListItemPrefix>
                        <i className="las la-exclamation-triangle text-1xl dark:text-white"></i>
                      </ListItemPrefix>

                      <span className={subOpen === 10 ? "font-bold p-1 dark:text-white" : "dark:text-white"}>
                        Régles
                      </span>
                    </ListItem>
                  </Link>
                </List>
              </AccordionBody>
            </Accordion>
            <Accordion
              open={open === 3}
              icon={
                <ChevronDownIcon
                  strokeWidth={2.5}
                  className={`mx-auto h-4 w-4 transition-transform dark:text-white${
                    open === 3 ? "rotate-180" : ""
                  }`}
                />
              }
            >
              <ListItem className="p-0" selected={open === 3}>
                <AccordionHeader onClick={() => handleOpen(3)} className="p-3 border-b-0">
                  <ListItemPrefix>
                    <i className="las la-users-cog dark:text-white"></i>
                  </ListItemPrefix>
                  <Typography color="blue-gray" className="mr-auto font-normal dark:text-white">
                    Utilisateurs
                  </Typography>
                </AccordionHeader>
              </ListItem>
              <AccordionBody className="py-1">
                <List className="p-0">
                  <Link to="/dashboard/creator-list" onClick={() => handleSubOpen(11)}>
                    <ListItem>
                      <ListItemPrefix>
                        <i className="las la-blog text-1xl dark:text-white"></i>
                      </ListItemPrefix>
                      <span className={subOpen === 11 ? "font-bold p-1 dark:text-white" : "dark:text-white"}>
                        Rédacteur web
                      </span>
                    </ListItem>
                  </Link>
                </List>
                <List className="p-0">
                  <Link to="/dashboard/owners" onClick={() => handleSubOpen(12)}>
                    <ListItem>
                      <ListItemPrefix>
                        <i className="las la-home text-1xl dark:text-white"></i>
                      </ListItemPrefix>
                      <span className={subOpen === 12 ? "font-bold p-1 dark:text-white" : "dark:text-white"}>
                        Propriétaire
                      </span>
                    </ListItem>
                  </Link>
                </List>
                <List className="p-0">
                  <Link to="/dashboard/agents" onClick={() => handleSubOpen(13)}>
                    <ListItem>
                      <ListItemPrefix>
                        <i className="las la-hands-helping text-1xl dark:text-white"></i>
                      </ListItemPrefix>{" "}
                      <span className={subOpen === 13 ? "font-bold p-1 dark:text-white" : "dark:text-white"}>
                        Prestataire
                      </span>
                    </ListItem>
                  </Link>
                </List>
                <List className="p-0">
                  <Link to="/dashboard/clients" onClick={() => handleSubOpen(14)}>
                    <ListItem>
                      <ListItemPrefix>
                        <i className="las la-user text-1xl dark:text-white"></i>
                      </ListItemPrefix>

                      <span className={subOpen === 14 ? "font-bold p-1 dark:text-white" : "dark:text-white"}>
                        Client
                      </span>
                    </ListItem>
                  </Link>
                </List>
              </AccordionBody>
            </Accordion>
            <Accordion
              open={open === 4}
              icon={
                <ChevronDownIcon
                  strokeWidth={2.5}
                  className={`mx-auto h-4 w-4 transition-transform dark:text-white${
                    open === 4 ? "rotate-180" : ""
                  }`}
                />
              }
            >
              <ListItem className="p-0" selected={open === 4}>
                <AccordionHeader onClick={() => handleOpen(4)} className="p-3 border-b-0">
                  <ListItemPrefix>
                    <i className="las la-stream dark:text-white"></i>
                  </ListItemPrefix>
                  <Typography color="blue-gray" className="mr-auto font-normal dark:text-white">
                    Prestations
                  </Typography>
                </AccordionHeader>
              </ListItem>
              <AccordionBody className="py-1">
                <List className="p-0">
                  <Link to="/dashboard/prestations" onClick={() => handleSubOpen(15)}>
                    <ListItem>
                      <ListItemPrefix>
                        <i className="las la-hands-helping dark:text-white"></i>
                      </ListItemPrefix>
                      <span className={subOpen === 15 ? "font-bold p-1 dark:text-white" : "dark:text-white"}>
                        Prestations
                      </span>
                    </ListItem>
                  </Link>
                </List>
              </AccordionBody>
              <AccordionBody className="py-1">
                <List className="p-0">
                  <Link to="/dashboard/fields" onClick={() => handleSubOpen(16)}>
                    <ListItem>
                      <ListItemPrefix>
                        <i className="las la-stream dark:text-white"></i>
                      </ListItemPrefix>
                      <span className={subOpen === 16 ? "font-bold p-1 dark:text-white" : "dark:text-white"}>
                        Types de prestation
                      </span>
                    </ListItem>
                  </Link>
                </List>
              </AccordionBody>
            </Accordion>
            <Accordion
              open={open === 5}
              icon={
                <ChevronDownIcon
                  strokeWidth={2.5}
                  className={`mx-auto h-4 w-4 transition-transform dark:text-white ${
                    open === 4 ? "rotate-180" : ""
                  }`}
                />
              }
            >
              <ListItem className="p-0" selected={open === 5}>
                <AccordionHeader onClick={() => handleOpen(5)} className="p-3 border-b-0">
                  <ListItemPrefix>
                    <i className="las la-tools dark:text-white"></i>
                  </ListItemPrefix>
                  <Typography color="blue-gray" className="mr-auto font-normal dark:text-white">
                    <span>Paramètre</span>
                  </Typography>
                </AccordionHeader>
              </ListItem>
              <AccordionBody className="">
                <List className="p-0">
                  <Link to="/dashboard/slider" onClick={() => handleSubOpen(17)}>
                    <ListItem>
                      <ListItemPrefix>
                        <i className="las la-images dark:text-white"></i>
                      </ListItemPrefix>
                      <span className={subOpen === 17 ? "font-bold p-1 dark:text-white" : "dark:text-white"}>
                        Slider
                      </span>
                    </ListItem>
                  </Link>
                </List>
                <List className="p-0">
                  <Link to="/dashboard/videos" onClick={() => handleSubOpen(18)}>
                    <ListItem>
                      <ListItemPrefix>
                        <i className="las la-play-circle dark:text-white"></i>
                      </ListItemPrefix>
                      <span className={subOpen === 18 ? "font-bold p-1 dark:text-white" : "dark:text-white"}>
                        Videos
                      </span>
                    </ListItem>
                  </Link>
                </List>

                <List className="p-0">
                  <Link to="/dashboard/home-videos" onClick={() => handleSubOpen(18)}>
                    <ListItem>
                      <ListItemPrefix>
                        <i className="las la-play-circle dark:text-white"></i>
                      </ListItemPrefix>
                      <span className={subOpen === 19 ? "font-bold p-1 dark:text-white" : "dark:text-white"}>
                        Home Videos
                      </span>
                    </ListItem>
                  </Link>
                </List>

                <List className="p-0">
                  <Link to="/dashboard/emails" onClick={() => handleSubOpen(24)}>
                    <ListItem>
                      <ListItemPrefix>
                        <i className="las la-envelope dark:text-white"></i>
                      </ListItemPrefix>
                      <span className={subOpen === 24 ? "font-bold p-1 dark:text-white" : "dark:text-white"}>
                        Emails
                      </span>
                    </ListItem>
                  </Link>
                </List>

                <List className="p-0">
                  <Link to="/dashboard/stripe-connect" onClick={() => handleSubOpen(25)}>
                    <ListItem>
                      <ListItemPrefix>
                        <i className="lab la-stripe-s dark:text-white"></i>
                      </ListItemPrefix>
                      <span className={subOpen === 25 ? "font-bold p-1 dark:text-white" : "dark:text-white"}>
                        Stripe connect
                      </span>
                    </ListItem>
                  </Link>
                </List>
              </AccordionBody>
            </Accordion>
            <Accordion
              open={open === 6}
              icon={
                <ChevronDownIcon
                  strokeWidth={2.5}
                  className={`mx-auto h-4 w-4 transition-transform dark:text-white ${
                    open === 5 ? "rotate-180" : ""
                  }`}
                />
              }
            >
              <ListItem className="p-0" selected={open === 6}>
                <AccordionHeader onClick={() => handleOpen(6)} className="p-3 border-b-0">
                  <ListItemPrefix>
                    <i className="las la-calendar dark:text-white"></i>
                  </ListItemPrefix>
                  <Typography color="blue-gray" className="mr-auto font-normal dark:text-white">
                    <span>Réservation</span>
                  </Typography>
                </AccordionHeader>
              </ListItem>
              <AccordionBody className="">
                <List className="p-0">
                  <ListItem>
                    <ListItemPrefix>
                      <i className="las la-calendar-check dark:text-white"></i>
                    </ListItemPrefix>
                    <Link
                      to="/dashboard/reservations/tentative-admin"
                      className={subOpen === 22 ? "font-bold dark:text-white" : "dark:text-white"}
                      onClick={() => handleSubOpen(22)}
                    >
                      Tentatives
                    </Link>
                  </ListItem>

                  <ListItem>
                    <ListItemPrefix>
                      <i className="las la-calendar-check dark:text-white"></i>
                    </ListItemPrefix>
                    <Link
                      to="/dashboard/reservations/pending-admin"
                      className={subOpen === 23 ? "font-bold dark:text-white" : "dark:text-white"}
                      onClick={() => handleSubOpen(23)}
                    >
                      En attente
                    </Link>
                  </ListItem>

                  <ListItem>
                    <ListItemPrefix>
                      <i className="las la-calendar-check dark:text-white"></i>
                    </ListItemPrefix>
                    <Link
                      to="/dashboard/reservation-admin"
                      className={subOpen === 19 ? "font-bold dark:text-white" : "dark:text-white"}
                      onClick={() => handleSubOpen(19)}
                    >
                      En cours
                    </Link>
                  </ListItem>
                  <ListItem>
                    <ListItemPrefix>
                      <i className="las la-calendar-plus dark:text-white"></i>
                    </ListItemPrefix>
                    <Link
                      to="/dashboard/pending-admin"
                      className={subOpen === 20 ? "font-bold dark:text-white" : "dark:text-white"}
                      onClick={() => handleSubOpen(20)}
                    >
                      {" "}
                      Demandes annulées
                    </Link>
                  </ListItem>
                  <ListItem>
                    <ListItemPrefix>
                      <i className="las la-calendar-times dark:text-white"></i>
                    </ListItemPrefix>
                    <Link
                      to="/dashboard/canceled-admin"
                      className={subOpen === 21 ? "font-bold dark:text-white" : "dark:text-white"}
                      onClick={() => handleSubOpen(21)}
                    >
                      Annulées par clients
                    </Link>
                  </ListItem>
                </List>
              </AccordionBody>

              <ListItem className="p-0">
                <ListItem>
                  <ListItemPrefix>
                    <i style={{ fontSize: "20px" }} className="las la-exchange-alt dark:text-white"></i>
                  </ListItemPrefix>
                  <Link className="dark:text-white" to="/dashboard/transactions-admin">
                    Transactions
                  </Link>
                </ListItem>
              </ListItem>

              <ListItem className="p-0">
                <ListItem>
                  <ListItemPrefix>
                    <i style={{ fontSize: "20px" }} className="las la-exclamation-triangle dark:text-white"></i>
                  </ListItemPrefix>
                  <Link className="dark:text-white" to="/dashboard/reclamation-admin">
                    Réclamations
                  </Link>
                </ListItem>
              </ListItem>
            </Accordion>
          </List>
        </Card>
      </div>
      <div className="container pb-24 pt-14 sm:pt-20 lg:pb-32">{children}</div>
    </div>
  );
};

export default DashboardMenu;
