import toast from "react-hot-toast";

const baseURL = process.env.REACT_APP_SERVER_URL;

export const locationApi = {
  createLocation: async (data: any) => {
    const formData = new FormData();

    for (const key in data) {
      if (data.hasOwnProperty(key) && key !== "newImages") {
        if (data[key] !== undefined && data[key] !== null) {
          if (Array.isArray(data[key])) {
            formData.append(key, JSON.stringify(data[key]));
          } else {
            formData.append(key, data[key]);
          }
        }
      }
    }

    if (data.newImages && Array.isArray(data.newImages)) {
      data.newImages.forEach((image: any, index: any) => {
        formData.append(`newImages`, image);
      });
    }

    const result = await fetch(`${baseURL}/api/location/`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
      body: formData,
    });
    return result.json();
  },

  updateLocation: async (data: any, id: any) => {
    const formData = new FormData();

    for (const key in data) {
      if (data.hasOwnProperty(key) && key !== "newImages") {
        if (data[key] !== undefined && data[key] !== null) {
          if (Array.isArray(data[key])) {
            formData.append(key, JSON.stringify(data[key]));
          } else {
            formData.append(key, data[key]);
          }
        }
      }
    }

    if (data.newImages && Array.isArray(data.newImages)) {
      data.newImages.forEach((image: any, index: any) => {
        formData.append(`newImages`, image);
      });
    }

    const result = await fetch(`${baseURL}/api/location/${id}`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
      body: formData,
    });
    return result.json();
  },

  getLocationById: async (id: string) => {
    const result = await fetch(`${baseURL}/api/location/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    return result.json();
  },

  getLocationAllDataById: async (id: string) => {
    const result = await fetch(`${baseURL}/api/location-data/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
    });

    return result.json();
  },

  getConditionsByIdLocation: async (id: string) => {
    const result = await fetch(`${baseURL}/api/condition/location/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    return result.json();
  },

  deleteConditionByLocationId: async (designations: string[], locationId: number) => {
    const deletePromises = designations.map(async (designation) => {
      const result = await fetch(`${baseURL}/api/condition/${designation}/${locationId}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
        body: JSON.stringify({
          designation: designation,
          locationId: locationId,
        }),
      });
      return result.json();
    });

    const results = await Promise.all(deletePromises);

    return results;
  },

  getEquipmentsByIdLocation: async (id: string) => {
    const result = await fetch(`${baseURL}/api/location-equipment/location/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    return result.json();
  },

  deleteEquipmentByLocationId: async (equipment: string[], locationId: number) => {
    const deletePromises = equipment.map(async (equipment) => {
      const result = await fetch(`${baseURL}/api/location-equipment/${equipment}/${locationId}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
        body: JSON.stringify({
          equipment: equipment,
          locationId: locationId,
        }),
      });
      return result.json();
    });

    const results = await Promise.all(deletePromises);

    return results;
  },

  getExtraEquipmentsByIdLocation: async (id: string) => {
    const result = await fetch(`${baseURL}/api/extra/location/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    return result.json();
  },

  getLocationPricesByIdLocation: async (id: string) => {
    const result = await fetch(`${baseURL}/api/price/location/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    return result.json();
  },

  deleteExtraEquipmentByLocationId: async (designations: string[], locationId: number) => {
    const deletePromises = designations.map(async (designation) => {
      const result = await fetch(`${baseURL}/api/extra/${designation}/${locationId}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
        body: JSON.stringify({
          designation: designation,
          locationId: locationId,
        }),
      });
      return result.json();
    });

    const results = await Promise.all(deletePromises);

    return results;
  },

  deletePricesByLocationId: async (prices: any[], locationId: number) => {
    const deletePromises = prices.map(async (price) => {
      const result = await fetch(`${baseURL}/api/price/${price}/${locationId}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
      });
      return result.json();
    });

    const results = await Promise.all(deletePromises);

    return results;
  },

  getAllLocations: async () => {
    const result = await fetch(`${baseURL}/api/location`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
    });

    return result.json();
  },

  // admin get all unrpouved locations
  getAllLocationsByAdmin: async (pageNumber: number) => {
    const result = await fetch(`${baseURL}/api/location/admin?page=${pageNumber}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
    });

    return result.json();
  },

  // admin get all approuved locations

  getAllApprouvedLocationsByAdmin: async (pageNumber: number) => {
    const result = await fetch(`${baseURL}/api/location/admin/approuved-locations?page=${pageNumber}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
    });

    return result.json();
  },

  getAllIncompletedLocationsByAdmin: async (pageNumber: number) => {
    const result = await fetch(`${baseURL}/api/location/admin/incompleted-locations?page=${pageNumber}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
    });

    return result.json();
  },

  validateLocation: async (data: any, id: any, stripeToken?: string, stripeBankToken?: string) => {
    try {
      const result = await fetch(`${baseURL}/api/location/approuve/${id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
        body: JSON.stringify({
          validation: data.validation,
          reason: data.reason,
          stripeToken: stripeToken,
          stripeBankToken: stripeBankToken,
        }),
      });

      if (!result.ok) {
        const errorData = await result.json();
        throw new Error(errorData.error || "An error occurred while updating location validation.");
      }
      if (data.validation === "bloqué") {
        toast.success("Espace bloqué avec success");
      } else if (data.validation === "approuvé") {
        toast.success("Espace approuvé avec success");
      } else {
        toast.success("Espace est en cours de traitement");
      }
      return result.json();
    } catch (error) {
      console.error("error", error);
      if (error instanceof Error) {
        toast.error(error.message);
      } else {
        toast.error("An unexpected error occurred.");
      }
      // Handle errors here
      throw new Error("Error updating location validation.");
    }
  },

  createConnectedAcountApi: async (data: any, stripeToken?: string, stripeBankToken?: string) => {
    try {
      const result = await fetch(`${baseURL}/api/created-connect`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
        body: JSON.stringify({
          data: data,
          stripeToken: stripeToken,
          stripeBankToken: stripeBankToken,
        }),
      });

      if (!result.ok) {
        const errorData = await result.json();
        throw new Error(errorData.error || "An error occurred while updating location validation.");
      }
      if (result.ok) {
        toast.success("Stripe Connect créé, utilisateur mis à jour avec succès");
      }
      return result;
    } catch (error) {
      console.error("error", error);
      if (error instanceof Error) {
        toast.error(error.message);
      } else {
        toast.error("An unexpected error occurred.");
      }
      // Handle errors here
      throw new Error("Error updating location validation.");
    }
  },

  updateLocationJustification: async (data: any, id: any) => {
    const formData = new FormData();

    for (const key in data) {
      {
        formData.append(key, data[key]);
      }
    }

    const result = await fetch(`${baseURL}/api/location/justification/${id}`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
      body: formData,
    });
    return result.json();
  },

  getLocationByUserId: async (id: string) => {
    const result = await fetch(`${baseURL}/api/location/owner/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    return result.json();
  },

  fetchLocationsPrices: async () => {
    const result = await fetch(`${baseURL}/api/min-price`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
    });

    return result.json();
  },

  getMinPriceByIdLocation: async (id: string) => {
    const result = await fetch(`${baseURL}/api/min-price/location/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    return result.json();
  },
};
