import { FC, useState } from "react";
export interface ReservationDetailedCardProps {
  className?: string;
  defaultOpen?: boolean;
  data: any;
}
const ReservationsDetailedCard: FC<ReservationDetailedCardProps> = ({
  className = "",
  data,
  defaultOpen = false,
}) => {
  function formatDateShort(dateString: any) {
    const date = new Date(dateString);
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }
  const [isOpen, setIsOpen] = useState(defaultOpen);

  const renderDetailTop = () => {
    return (
      <div>
        <div className="flex flex-col justify-center md:flex-row">
          <div className="flex my-5 md:my-0">
            <div className="ml-4 space-y-10 text-sm">
              <div className="flex flex-col space-y-1">
                <span className="font-semibold text-neutral-950">
                  <i className="text-xl las la-user dark:text-white"></i>
                  <span className="dark:text-white">Client:</span>
                  <span className="text-neutral-500 dark:text-neutral-400">
                    {data?.Client?.name} {data?.Client?.lastName}
                  </span>
                </span>
              </div>
              <div className="flex flex-col space-y-1">
                <span className="font-semibold text-neutral-950">
                  <i className="text-xl las la-users dark:text-white"></i>
                  <span className="dark:text-white">Personnes autorisées :</span>
                  <span className="text-neutral-500 dark:text-neutral-400">{data?.Location?.visitor}</span>
                </span>
              </div>
            </div>
          </div>
          <div className="border-l border-neutral-200 dark:border-neutral-700 md:mx-6 lg:mx-10"></div>
          <div className="ml-4 space-y-10 text-sm">
            <div className="space-y-1">
              <span className="font-semibold text-neutral-950">
                <i className="text-xl las la-hourglass-start dark:text-white"> </i>
                <span className="dark:text-white">Heure de début :</span>H
                <span className="text-neutral-500 dark:text-neutral-400">{data.startHour}</span>
              </span>
            </div>
            <div className="space-y-1">
              <span className="font-semibold text-neutral-950">
                <i className="text-xl las la-hourglass-end dark:text-white"></i>
                <span className="dark:text-white"> Heure de sortie : </span>
                <span className="text-neutral-500 dark:text-neutral-400">{data?.endHour}</span>
              </span>
            </div>
            <div className="flex flex-col space-y-1">
              <span className="font-semibold text-neutral-950 dark:text-white">
                <span className="dark:text-white"> Code réservation : </span>
                <span className="text-neutral-500 dark:text-neutral-400">{data?.reference}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderDetail = () => {
    if (!isOpen) return null;
    return (
      <div className="p-4 border rounded-2xl md:p-8 border-neutral-200 dark:border-neutral-700">
        {renderDetailTop()}
      </div>
    );
  };
  const currentDate = new Date();
  const selectedDate = new Date(data?.selectedDate);
  const isDateNotPassed = selectedDate > currentDate;
  const currentHour = currentDate.getHours();
  const endHour = (data?.endHour).slice(0, -3);
  const isTerminated = currentHour > endHour;
  return (
    <div
      className={`overflow-hidden relative p-4 space-y-6 bg-white rounded-2xl border transition-shadow nc-FlightCardgroup sm:p-6 dark:bg-neutral-900 border-neutral-100 dark:border-neutral-800 hover:shadow-lg ${className}`}
      data-nc-id="FlightCard"
    >
      <div className={`relative sm:pr-20 ${className}`} data-nc-id="FlightCard">
        <a
          href={
            data.payment_status == "succeeded" && isDateNotPassed && data.validation == true
              ? `/location/${data?.Location?.id}?reservationId=${data.id}` + "&openconverstaion=true"
              : `/location/${data?.Location?.id}`
          }
          className="absolute inset-0"
        />
        <span
          className={`absolute right-0 bottom-0 sm:bottom-auto sm:top-1/2 sm:-translate-y-1/2 w-10 h-10 bg-neutral-50 dark:bg-neutral-800 rounded-full flex items-center justify-center cursor-pointer ${
            isOpen ? "transform -rotate-180" : ""
          }`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <i className="text-xl las la-angle-down"></i>
        </span>

        <div className="flex flex-col justify-between">
          {/* FOR MOBILE RESPONSIVE */}
          <div className="block ml-12 space-y-1 lg:hidden">
            <div className="flex items-center font-semibold">
              <div className="flex-1">{data?.Location?.title}</div>
              <div className="flex justify-center w-12">
                <i className="text-2xl las la-long-arrow-alt-right"></i>
              </div>
              <div className="flex-1 text-right">
                <span className="text-sm font-normal text-neutral-500">{formatDateShort(data?.selectedDate)}</span>
              </div>
            </div>

            <div className="flex items-center font-semibold">
              <div className="flex-1">Montant:</div>
              <div className="flex justify-center w-12">
                <i className="text-2xl las la-long-arrow-alt-right"></i>
              </div>
              <div className="flex-1 text-right">
                <span className="text-sm font-normal text-neutral-500">
                  {" "}
                  {(parseFloat(data.halfTotal) + parseFloat(data.extra)).toFixed(2)} €
                </span>
              </div>
            </div>
          </div>
          <div className="flex justify-between ml-12 space-y-1">
            <div className="flex-shrink-0 w-24 lg:w-32 hidden lg:block  mr- flex-[4] ">
              <img
                src={`${process.env.REACT_APP_CLOUD_FRONT}${data?.Location?.images[0]}`}
                className="mb-4 w-25"
                alt=""
              />
            </div>
            <div className="hidden lg:block min-w-[150px] flex-[4] ml-12">
              <div className="text-lg font-medium"> {data?.Location?.title}</div>
            </div>

            <div className="hidden lg:block flex-[4] whitespace-nowrap ml-4">
              <div className="text-lg font-medium">Date</div>
              <div className="text-sm text-neutral-500 font-normal mt-0.5">
                {formatDateShort(data?.selectedDate)}
              </div>
            </div>

            <div className="hidden lg:block flex-[4] whitespace-nowrap ml-4">
              <div className="text-lg font-medium">Prix</div>
              <div className="text-sm text-neutral-500 font-normal mt-0.5">{data?.price} €</div>
            </div>

            <div className="flex justify-between">
              <div className="text-center whitespace-nowrap sm:text-right">
                {data.payment_status === "succeeded" &&
                  data.approuved == true &&
                  isDateNotPassed &&
                  data.validation == true && (
                    <span className="text-xl font-semibold text-secondary-6000">En cours</span>
                  )}
                {data.payment_status == "canceled" && (
                  <span className="text-xl font-semibold text-red-600">Annulée</span>
                )}
                {!isDateNotPassed &&
                  isTerminated &&
                  data.payment_status !== "canceled" &&
                  data.approuved === true && <span className="text-xl font-semibold text-gray-600">Terminée</span>}
                {!isDateNotPassed &&
                  !isTerminated &&
                  data.payment_status === "succeeded" &&
                  data.approuved === true && (
                    <span className="text-xl font-semibold text-gray-600">Obsolète </span>
                  )}
              </div>

              <div className="ml-4 text-center whitespace-nowrap sm:text-right">
                {" "}
                {data.payment_status === "succeeded" &&
                  data.approuved == true &&
                  isDateNotPassed &&
                  data.validation == true && (
                    <div className="flex-[4] whitespace-nowrap sm:text-right">
                      <div>
                        <span style={{ cursor: "pointer" }} className="text-xl font-semibold text-secondary-6000">
                          Message
                        </span>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {renderDetail()}
    </div>
  );
};

export default ReservationsDetailedCard;
