import { MagnifyingGlassCircleIcon } from "@heroicons/react/24/solid";
import DeleteModal from "components/DeleteModal/DeleteModal";
import { User } from "data/types";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { deleteUser, getAllOwners } from "redux/features/auth/authSlice";
import Input from "shared/Input/Input";
import Pagination from "shared/Pagination/Pagination";
import DashboardMenu from "../../DashboardPage/DashboardMenu";
import OwnerDetailsPage from "./OwnerDetailsPage";
import { itemPerDashborad } from "data/constants";

export interface ListCategorysPageProps {
  className?: string;
}

const ListOwnersPage: FC<ListCategorysPageProps> = ({ className = "" }) => {
  const dispatch = useAppDispatch();
  const owners = useAppSelector((state) => state?.auth?.allOwners);
  const filteredOwners = Array.isArray(owners) && owners.filter((owner) => owner);
  useEffect(() => {
    dispatch(getAllOwners());
  }, []);

  const [searchTerm, setSearchTerm] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = itemPerDashborad;

  const paginatedOwners = Array.isArray(filteredOwners)
    ? filteredOwners.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
    : [];

  const handlePageChange = (pageNumber: any) => {
    setCurrentPage(pageNumber);
  };

  const length = Array.isArray(filteredOwners) ? filteredOwners.length : 0;
  const totalPages = Array.isArray(filteredOwners) ? Math.ceil(filteredOwners.length / itemsPerPage) : 0;

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedOwner, setselectedOwner] = useState<User | null>(null);
  const [showOwnerDetails, setshowOwnerDetails] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const handleDeleteModalOpen = (client: User) => {
    setselectedOwner(client);
    setshowOwnerDetails(false);
    setDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (selectedOwner && selectedOwner.User?.id !== undefined) {
      setDeleting(true);
      await dispatch(deleteUser(selectedOwner.User?.id));
      setDeleting(false);
      setDeleteModalOpen(false);
      await dispatch(getAllOwners());
    }
  };

  const handleEyeIconClick = (client: any) => {
    setselectedOwner(client);
    setshowOwnerDetails(true);
    setDeleteModalOpen(false);
  };

  const paginatedSearchedCOwners = Array.isArray(owners)
    ? owners
        .filter((item) => {
          const name = item.name || "";
          const lastName = item.lastName || "";
          const companyName = item.companyName || "";
          return (
            name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            companyName.toLowerCase().includes(searchTerm.toLowerCase())
          );
        })
        .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
    : [];

  const displayedOwners = searchTerm === "" ? paginatedOwners : paginatedSearchedCOwners;

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Event To Bee</title>
      </Helmet>
      <DashboardMenu>
        <div className="flex flex-col">
          <div className="flex items-center justify-between mb-4">
            <div></div>
            <div className="relative flex">
              <Input
                type="text"
                placeholder="Rechercher par nom"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <MagnifyingGlassCircleIcon className="absolute w-6 h-6 text-gray-500 transform -translate-y-1/2 top-1/2 right-3" />
            </div>
          </div>
          {deleteModalOpen && (
            <DeleteModal
              open={deleteModalOpen}
              handleClose={() => setDeleteModalOpen(false)}
              handleDelete={handleDeleteConfirm}
              category={{
                id: selectedOwner?.User?.id || 0,
                designation: selectedOwner?.User?.login || "",
              }}
            />
          )}

          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
              <div className="overflow-hidden">
                <table className="min-w-full text-sm font-light text-center">
                  <thead className="font-medium text-white border-b bg-neutral-800 dark:border-neutral-500 dark:bg-neutral-900">
                    <tr>
                      <th scope="col" className="px-6 py-4 ">
                        Nom
                      </th>
                      <th scope="col" className="px-6 py-4 ">
                        Consulter
                      </th>
                      <th scope="col" className="px-6 py-4 ">
                        Supprimer
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {searchTerm === ""
                      ? paginatedOwners.map((item, index) => (
                          <tr className="border-b dark:border-neutral-500" key={index}>
                            <td className="px-6 py-4 font-medium whitespace-nowrap">
                              {item.lastName} {item.name} {item.companyName}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <i
                                className="text-2xl cursor-pointer las la-eye"
                                onClick={() => handleEyeIconClick(item)}
                              ></i>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <i
                                className="text-2xl cursor-pointer las la-trash-alt"
                                onClick={() => handleDeleteModalOpen(item)}
                              ></i>
                            </td>
                          </tr>
                        ))
                      : displayedOwners.map((item, index) => (
                          <tr className="border-b dark:border-neutral-500" key={index}>
                            <td className="px-6 py-4 font-medium whitespace-nowrap">
                              {item.lastName} {item.name} {item.companyName}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <i
                                className="text-2xl cursor-pointer las la-eye"
                                onClick={() => handleEyeIconClick(item)}
                              ></i>{" "}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <i
                                className="text-2xl cursor-pointer las la-trash-alt"
                                onClick={() => handleDeleteModalOpen(item)}
                              ></i>
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </table>
                <div className="flex justify-end ">
                  <span className="text-sm text-gray-700">{length} élément</span>
                </div>
                {showOwnerDetails && !deleting && (
                  <OwnerDetailsPage
                    open={true}
                    handleClose={() => setshowOwnerDetails(false)}
                    ownerData={selectedOwner}
                  />
                )}
                <div className="flex items-center justify-center mt-11">
                  {typeof totalPages === "number" && (
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={handlePageChange}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </DashboardMenu>
    </div>
  );
};

export default ListOwnersPage;
