const baseURL = process.env.REACT_APP_SERVER_URL;

export const stripeApi = {
  getBalance: async () => {
    const result = await fetch(`${baseURL}/api/balance`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
    });

    return result.json();
  },

  getAllTransactions: async () => {
    const response = await fetch(`${baseURL}/api/transfers`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
    });
    const data = await response.json();
    return data;
  },
};
