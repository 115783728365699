import { FC, useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import { z } from "zod";
import JoditEditor, { Jodit } from "jodit-react";
import HTMLReactParser from "html-react-parser";
import { TrashIcon } from "@heroicons/react/24/solid";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { AuthContext } from "context/appContext";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { addBlog } from "redux/features/blog/blogSlice";
import { useNavigate } from "react-router-dom";
import * as blogSlice from "redux/features/blog/blogSlice";

export interface AddArticlePageProps {
  className?: string;
}

const AddArticlePage: FC<AddArticlePageProps> = ({ className = "" }) => {
  const authContext = useContext(AuthContext);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const MAX_FILE_SIZE = 1024 * 1024 * 10;
  const ACCEPTED_IMAGE_MIME_TYPES = ["image/jpeg", "image/jpg", "image/png", "image/gif", "image/webp"];
  const blogState = useAppSelector((state) => state.featureBlog);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const editor = useRef(null);
  //const [content, setContent] = useState("");
  const formSchema = z.object({
    title: z.string().min(1, { message: "Titre est obligatoire." }),
    meta_title: z.string().min(1, { message: "Meta titre est obligatoire." }),
    meta_description: z.string().min(1, { message: "Meta description est obligatoire." }),

    tags: z.array(z.string()).min(1, { message: "tag est obligatoire." }),
    description: z.string().min(50, {
      message: "description est obligatoire doit contenir au minimum 50 caractères.",
    }),
    blog_url: z
      .instanceof(File, { message: "L'image est obligatoire." })
      .refine(
        (file) => !file || (file?.size <= MAX_FILE_SIZE && ACCEPTED_IMAGE_MIME_TYPES.includes(file?.type)),
        "Le fichier doit être une image au format JPEG, PNG ou GIF et ne pas dépasser 10MB."
      ),
  });
  const {
    register,
    handleSubmit,
    getValues,
    trigger,
    setValue,
    reset,
    formState: { errors },
  } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {},
    mode: "onSubmit",
  });

  const onSubmit: SubmitHandler<z.infer<typeof formSchema>> = async (data) => {
    await dispatch(
      addBlog({
        userId: authContext?.user?.id,
        content: content,
        publisher: authContext?.user?.login,
        ...data,
      })
    ).then(() => {
      reset();
      setContent("");
      navigate("/dashboard-creator");
    });
  };
  const [tag, setTag] = useState<string>("");

  const handleAddTag = () => {
    if (tag.trim() !== "") {
      const dd = getValues("tags") || [];
      setValue("tags", [...dd, tag.trim()]);
      trigger("tags");
      setTag("");
    }
  };
  const handleDropImage = (e: React.DragEvent<HTMLDivElement>, fieldName: any) => {
    e.preventDefault();
    const droppedFiles = e.dataTransfer.files;
    if (droppedFiles) {
      const file = droppedFiles[0];
      setValue("blog_url", file);
      trigger("blog_url");
    }
  };
  const handleDeleteImage = () => {
    setValue("blog_url", new File([], ""));
  };
  useEffect(() => {
    switch (blogState.loading) {
      case "pending": {
        setIsLoading(true);
        break;
      }
      case "succeeded": {
        setIsLoading(false);
        break;
      }
      case "failed": {
        setIsLoading(false);
        break;
      }
      default:
        setIsLoading(false);
    }
  }, [blogState.loading]);
  useEffect(() => {
    dispatch(blogSlice.initializeState());
  }, []);
  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };
  const handleRemoveTag = (index: number) => {
    const newTags = [...getValues("tags")];
    newTags.splice(index, 1);
    setValue("tags", newTags);
    trigger("tags");
  };

  const [content, setContent] = useState<string>("");
  const editorRef = useRef<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    if (editorRef.current) {
      const editor = new Jodit(editorRef.current, {
        buttons: [
          "font",
          "bold",
          "italic",
          "underline",
          "|",
          "justifyleft",
          "justifycenter",
          "justifyright",
          "|",
          "fontsize",
          "brush",
          "paragraph",
          "eraser",
          "link",
          "unlink",
          "|",
          "source",
          "image",
          "video",
        ],
        uploader: {
          insertImageAsBase64URI: false,
          imagesExtensions: ["jpg", "png", "jpeg", "gif", "webp"],
          withCredentials: false,
          format: "json",
          method: "POST",
          url: `${process.env.REACT_APP_SERVER_URL}/api/blog/image`,
          prepareData: function (Data: any) {
            var file = Data.getAll("files[0]")[0];
            const formData = new FormData();
            formData.append("image", file);
            formData.delete("files[0]");
            return formData;
          },
          isSuccess: function (resp: any) {
            return !resp.error;
          },
          getMsg: function (resp: any) {
            return resp.msg.join !== undefined ? resp.msg.join(" ") : resp.msg;
          },
          process: function (resp: any) {
            editor.selection.insertImage(resp.imageUrl);
            return resp.imageUrl;
          },
        },
      });

      editor.events.on("change", (newContent) => {
        setContent(newContent);
      });
    }
  }, []);

  return (
    <div className={`nc-AccountPage ${className} mb-20 ml-10 mr-10`} data-nc-id="AccountPage">
      <Helmet>
        <title>Event To Bee</title>
      </Helmet>

      <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data" id="particulier">
        <h2 className="text-2xl font-semibold text-center ">Création d'un Nouvel Article</h2>
        <div className="">
          <br />
          <div className="max-w-screen-md mx-auto space-y-5 ">
            <div>
              <h2 className="mb-2 text-base font-semibold ">Titre *</h2>
              <Input
                type="text"
                placeholder="Entrez le titre de votre article"
                {...register("title")}
                name="title"
              />
              {errors.title?.message && <div className="text-xs text-red-500 ">{errors.title?.message}</div>}
            </div>

            <div>
              <h2 className="mb-2 text-base font-semibold ">Meta Titre *</h2>
              <Input
                type="text"
                placeholder="Entrez le meta titre de votre article"
                {...register("meta_title")}
                name="meta_title"
              />
              {errors.meta_title?.message && (
                <div className="text-xs text-red-500 ">{errors.meta_title?.message}</div>
              )}
            </div>
            <div></div>
            <h2 className="mt-2 mb-2 text-base font-semibold ">Description *</h2>
            <Textarea
              placeholder="Entrez la description de votre article"
              rows={7}
              {...register("description")}
              name="description"
            />
            {errors.description?.message && (
              <div className="text-xs text-red-500 ">{errors.description?.message}</div>
            )}

            <div>
              <h2 className="mb-2 text-base font-semibold ">Meta Description *</h2>
              <Input
                type="text"
                placeholder="Entrez le meta description de votre article"
                {...register("meta_description")}
                name="meta_description"
              />
              {errors.meta_description?.message && (
                <div className="text-xs text-red-500 ">{errors.meta_description?.message}</div>
              )}
            </div>
            <div>
              <h2 className="mb-2 text-base font-semibold ">Tag *</h2>
              <div className="flow-root">
                <div className="-my-3 divide-y divide-neutral-100 dark:divide-neutral-800">
                  {getValues("tags")?.map((tag, index) => (
                    <div key={index} className="flex items-center justify-between py-3">
                      <span className="font-medium text-neutral-6000 dark:text-neutral-400">{tag}</span>
                      <i
                        className="text-2xl cursor-pointer text-neutral-400 las la-times-circle hover:text-neutral-900 dark:hover:text-neutral-100"
                        onClick={() => handleRemoveTag(index)}
                      ></i>
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex flex-col space-y-3 sm:flex-row sm:justify-between sm:space-y-0 sm:space-x-5">
                <Input
                  className="!h-full"
                  placeholder="type..."
                  value={tag}
                  onChange={(e) => setTag(e.target.value)}
                  name="tag"
                />
                <ButtonPrimary className="flex-shrink-0" type="button" onClick={handleAddTag}>
                  <i className="text-xl las la-plus"></i>
                  <span className="ml-3">Ajouter</span>
                </ButtonPrimary>
              </div>
              {errors.tags?.message && (
                <div className="text-xs text-red-500">{errors.tags?.message.toString()}</div>
              )}

              <div></div>
            </div>

            <div className="mt-5 " onDrop={(e) => handleDropImage(e, "blog_url")} onDragOver={handleDragOver}>
              {getValues("blog_url") && (
                <div className="mt-3">
                  <p className="text-sm text-green-500">Votre image:</p>
                  <div className="flex items-center justify-center">
                    <div className="relative">
                      <img
                        src={getValues("blog_url") ? URL.createObjectURL(getValues("blog_url") as File) : ""}
                        className="h-auto max-w-md max-h-60"
                      />
                      {/* <TrashIcon
                        className="absolute w-5 text-white bg-black rounded-full cursor-pointer h-7 las la-trash-alt top-2 right-2"
                        onClick={handleDeleteImage}
                      /> */}
                    </div>
                  </div>
                </div>
              )}

              <div className="flex justify-center px-6 pt-5 pb-6 mt-1 border-2 border-dashed rounded-md border-neutral-300 dark:border-neutral-6000">
                <div className="space-y-1 text-center">
                  <svg
                    className="w-12 h-12 mx-auto text-neutral-400"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 48 48"
                    aria-hidden="true"
                  >
                    <path
                      d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                  <div className="flex text-sm text-neutral-6000 dark:text-neutral-300">
                    <label
                      htmlFor="fileInput"
                      className="relative font-medium rounded-md cursor-pointer text-primary-6000 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                    >
                      <span>Ajouter</span>
                      <input
                        id="fileInput"
                        name="image"
                        type="file"
                        multiple
                        className="sr-only"
                        onChange={(e) => {
                          const file = e.target.files?.[0];
                          if (file) {
                            setValue("blog_url", file);
                            trigger("blog_url");
                          }
                        }}
                        accept="image/jpg,image/jpeg,image/png,image/webp"
                      />
                    </label>
                    <p className="pl-1">ou déposer</p>
                  </div>
                  <p className="text-xs text-neutral-500 dark:text-neutral-400">PNG, JPG, GIF jusqu'à 10MB</p>
                </div>
              </div>
            </div>
            {errors.blog_url?.message && (
              <div className="mt-1 text-xs text-red-500">{errors.blog_url?.message}</div>
            )}
            <br />
            <h2 className="text-base font-semibold text-center ">Rédiger la page Article *</h2>
          </div>
        </div>
        <br />

        <textarea ref={editorRef} value={content} onChange={(e) => setContent(e.target.value)} />
        <br />
        <div className="mt-2 space-x-3">
          <ButtonPrimary type="submit" loading={isLoading}>
            Sauvgarder
          </ButtonPrimary>
          <ButtonSecondary type="button" href={"/dashboard-creator"}>
            Annuler
          </ButtonSecondary>
        </div>
      </form>
      <br />
      <br />
      <h2 className="mb-2 text-2xl font-semibold text-center ">Aperçu</h2>
      <div className="max-w-screen-md mx-auto space-y-5 ">
        <br />
        <div>{HTMLReactParser(content)}</div>
      </div>
    </div>
  );
};

export default AddArticlePage;
