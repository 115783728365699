import { FC, useEffect } from "react";
import { Helmet } from "react-helmet";
import CommonLayout from "./DashboardMenu";
import { UserCircleIcon } from "@heroicons/react/24/solid";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { getAllClients, getAllCreators, getAllOwners, getAllServiceAgents } from "redux/features/auth/authSlice";
import { CurrencyEuroIcon, HomeIcon } from "@heroicons/react/24/outline";
import { getAllIncompletedLocationsByAdmin, getAllLocationsByAdmin } from "redux/features/location/locationSlice";
import { getBalance } from "redux/features/Admin/Stripe/stripeSlice";

export interface AdminProfileProps {
  className?: string;
}

const AdminProfile: FC<AdminProfileProps> = ({ className = "" }) => {
  const dispatch = useAppDispatch();
  const clients = useAppSelector((state) => state?.auth?.allClients);
  const balance = useAppSelector((state) => state?.stripe);

  const lengthClient = Array.isArray(clients) ? clients.length : 0;
  const inProgressLocations = useAppSelector((state) => state?.location?.unApprouvedLocations);
  const inCompletedLocations = useAppSelector((state) => state?.location?.incompletedLocations);

  const owners = useAppSelector((state) => state?.auth?.allOwners);
  const lengthOwner = Array.isArray(owners) ? owners.length : 0;

  const serviceAgents = useAppSelector((state) => state?.auth?.allServiceAgents);
  const lengthServiceAgents = Array.isArray(serviceAgents) ? serviceAgents.length : 0;

  const creators = useAppSelector((state) => state?.auth?.allCreators);
  const lengthCreators = Array.isArray(creators) ? creators.length : 0;

  useEffect(() => {
    dispatch(getAllLocationsByAdmin(1));
    dispatch(getAllIncompletedLocationsByAdmin(1));
    dispatch(getAllClients());
    dispatch(getAllOwners());
    dispatch(getAllServiceAgents());
    dispatch(getAllCreators());
    dispatch(getBalance());
  }, []);

  const available_amount = balance?.balanceData?.available?.[0]?.amount / 100 || 0;
  const pending_amount = balance?.balanceData?.pending?.[0]?.amount / 100 || 0;

  const statisticCards = [
    {
      title: "Total clients",
      value: lengthClient,
      color: "cyan",
      icon: (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
          <UserCircleIcon />
        </svg>
      ),
    },
    {
      title: "Total propriétaires",
      value: lengthOwner,
      color: "cyan",
      icon: (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
          <UserCircleIcon />
        </svg>
      ),
    },
    {
      title: "Total prestataires ",
      value: lengthServiceAgents,
      color: "cyan",
      icon: (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
          <UserCircleIcon />
        </svg>
      ),
    },
    {
      title: "Total rédacteur",
      value: lengthCreators,
      color: "cyan",
      icon: (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
          <UserCircleIcon />
        </svg>
      ),
    },
    {
      title: "Espaces en attente",
      value: inProgressLocations?.totalCount,
      color: "cyan",
      icon: (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
          <HomeIcon />
        </svg>
      ),
    },
    {
      title: "Espaces non complétés ",
      value: inCompletedLocations?.totalCount,
      color: "cyan",
      icon: (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
          <HomeIcon />
        </svg>
      ),
    },

    {
      title: "Solde disponible",
      value: available_amount + " €",
      color: "cyan",
      icon: (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
          <CurrencyEuroIcon />
        </svg>
      ),
    },

    {
      title: "Solde en attente ",
      value: pending_amount + " €",
      color: "cyan",
      icon: (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
          <CurrencyEuroIcon />
        </svg>
      ),
    },
  ];

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Event To Bee</title>
      </Helmet>

      <CommonLayout>
        <div className="space-y-6 sm:space-y-8">
          <div className="container max-w-6xl px-5 mx-auto my-28">
            <div className="grid gap-7 sm:grid-cols-2 lg:grid-cols-4">
              {statisticCards.map((card, index) => (
                <div key={index} className="px-2 py-5 bg-white rounded shadow-sm dark:bg-gray-800">
                  <div className="flex items-center space-x-4">
                    <div>
                      <div
                        className={`flex items-center justify-center w-12 h-12 rounded-full bg-${card.color}-50 text-${card.color}-400`}
                      >
                        {card.icon}
                      </div>
                    </div>
                    <div>
                      <div className="text-sm ">{card.title}</div>
                      <div className="text-2xl font-bold ">{card.value}</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AdminProfile;
