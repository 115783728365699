const baseURL = process.env.REACT_APP_SERVER_URL;

const getCsrfToken = async () => {
  const result = await fetch(`${baseURL}/api/auth/csrf`, {
    method: "GET",
    credentials: "include",
  });
  return result.json();
};

export const authService = {
  currentUser: async () => {
    const result = await fetch(`${baseURL}/api/auth/current`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
    });

    if (result.status === 401) {
      return null;
    }

    return result.json();
  },

  searchUser: async (condition: any) => {
    const queryParams = new URLSearchParams();
    if (condition.role) {
      queryParams.append("role", condition.role);
    }
    if (condition.login) {
      queryParams.append("login", condition.login);
    }
    const result = await fetch(`${baseURL}/api/user/search?${queryParams.toString()}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
    });

    return result.json();
  },

  getUser: async (condition: any) => {
    const result = await fetch(`${baseURL}/api/user/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
      body: JSON.stringify(condition),
    });

    return result.json();
  },

  getUserById: async (id: any) => {
    const result = await fetch(`${baseURL}/api/user/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
    });

    return result.json();
  },

  getAllUsers: async () => {
    const result = await fetch(`${baseURL}/api/auth/users`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
    });

    return result.json();
  },

  updateCreator: async (data: any) => {
    const result = await fetch(`${baseURL}/api/auth/new-password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
      body: JSON.stringify(data),
    });
    return result.json();
  },

  resetPassword: async (data: any) => {
    const result = await fetch(`${baseURL}/api/auth/reset-password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
      body: JSON.stringify(data),
    });
    return result.json();
  },

  // Get all clients
  getAllClients: async () => {
    const result = await fetch(`${baseURL}/api/client`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
    });

    return result.json();
  },
  // clients stats
  getAllClientsStats: async () => {
    const result = await fetch(`${baseURL}/api/client/month`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
    });

    return result.json();
  },

  // Get all owners
  getAllOwners: async () => {
    const result = await fetch(`${baseURL}/api/client/owner`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        //Authorization: `Bearer ${localStorage.getItem('token') ?? ''}`,
      },
    });

    return result.json();
  },

  getOwners: async () => {
    const result = await fetch(`${baseURL}/api/list-owner`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        //Authorization: `Bearer ${localStorage.getItem('token') ?? ''}`,
      },
    });

    return result.json();
  },

  getAllOwnerStats: async () => {
    const result = await fetch(`${baseURL}/api/owner/month`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
    });

    return result.json();
  },

  // Get all service agents
  getAllServiceAgents: async () => {
    const result = await fetch(`${baseURL}/api/service-agent`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
    });

    return result.json();
  },

  getAllServiceAgentStats: async () => {
    const result = await fetch(`${baseURL}/api/service-agent/month`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
    });

    return result.json();
  },

  // Get all service creators
  getAllCreators: async () => {
    const result = await fetch(`${baseURL}/api/user`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
    });

    const allUsers = await result.json();
    const creators = allUsers.filter((user: { role: string }) => user.role === "creator");

    return creators;
  },

  // delete user
  deleteUser: async (id: number) => {
    const response = await fetch(`${baseURL}/api/user/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
    });
    const data = await response.json();
    return data;
  },

  validateClient: async (data: any, id: any) => {
    try {
      const result = await fetch(`${baseURL}/api/client/approuve/${id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
        body: JSON.stringify({ validation: data.validation }),
      });
      return result.json();
    } catch (error) {
      console.error(error);
      // Handle errors here
      throw new Error("Error updating location validation.");
    }
  },

  clientValidation: async (data: any, id: any) => {
    try {
      const result = await fetch(`${baseURL}/api/client/validation/${id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
        body: JSON.stringify({ validation: data.validation }),
      });
      return result.json();
    } catch (error) {
      console.error(error);
      // Handle errors here
      throw new Error("Error updating location validation.");
    }
  },

  getOwnerById: async (id: any) => {
    const result = await fetch(`${baseURL}/api/client/owner/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        //Authorization: `Bearer ${localStorage.getItem('token') ?? ''}`,
      },
    });

    return result.json();
  },

  sendVerificationEmail: async (email: any) => {
    const result = await fetch(`${baseURL}/api/auth/send-verification-email`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    });

    return result.json();
  },

  chekVerificationOTP: async (email: any, otp: any) => {
    const result = await fetch(`${baseURL}/api/auth/chek-verification-otp`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, otp }),
    });

    return result.json();
  },
};
